import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import toArrayBuffer from 'to-array-buffer'
import axios from 'axios'
import moment from 'moment'
import Modal from 'react-bootstrap/Modal'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import ReactTagInput from '@pathofdev/react-tag-input'
import '@pathofdev/react-tag-input/build/index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faPlus,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import Toast from '../../../../../Components/Toast/Toast'

import { edit_svg_icon, del_svg_icon } from '../../../../../constants_file'

import './FormPages.css'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'

const Professional_Info_Form = (props) => {
  const exp_InputRef = useRef()
  const edu_InputRef = useRef()
  const cer_InputRef = useRef()
  const date = moment(new Date())
  const today_date = date.format('YYYY-MM-DD')
  const params = useParams()

  // let indexkey;
  const [doc_name, setDoc_name] = useState(
    'Upload the related documents in jpg, png or pdf.'
  )
  const [edu_doc_name, setEdu_Doc_name] = useState(
    'Upload the related documents in jpg, png or pdf.'
  )
  const [cer_doc_name, setCer_doc_name] = useState(
    'Upload the related documents in jpg, png or pdf.'
  )
  const [doc_upload, setDoc_upload] = useState(false)
  const [edu_doc_upload, setEdu_Doc_upload] = useState(false)
  const [cer_doc_upload, setCer_doc_upload] = useState(false)
  // const [uploadExpImg, setUploadExpImg] = useState("");
  const stage = '3'
  const [index_update, setIndex_update] = useState('')
  const [show, setShow] = useState(false)
  const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false)
  const [Show_update_exp_modal, setShow_update_exp_modal] = useState(false)
  const [Show_update_edu_modal, setShow_update_edu_modal] = useState(false)
  const [Show_update_cer_modal, setShow_update_cer_modal] = useState(false)

  const handlebalShow = () => setShow(true)
  const close_update_exp_modal = () => setShow_update_exp_modal(false)
  const close_update_edu_modal = () => setShow_update_edu_modal(false)
  const close_update_cre_modal = () => setShow_update_cer_modal(false)

  const [Createspin, setCreateSpin] = useState('')
  const [docUploadSpin, setDocUploadSpin] = useState('')
  const [edudocUploadSpin, setEduDocUploadSpin] = useState('')
  const [cerdocUploadSpin, setCerdocUploadSpin] = useState('')

  const [ToastStates, setToastStates] = useState({
    message: '',
    show: '',
    color: '',
    icon: faTimesCircle,
  })

  // add experince modale state
  const [addExperince, setAddExperince] = useState({
    title: '',
    employment_type: '',
    company: '',
    location: '',
    start_date: '',
    end_date: '',
    currently_work: false,
    url: '',
  })
  // add education modal state
  const [addEducation, setAddEducation] = useState({
    institute_name: '',
    degree: '',
    field_of_study: '',
    location: '',
    start_date: '',
    end_date: '',
    url: '',
  })

  const [addCertification, setAddCertification] = useState({
    title: '',
    institute: '',
    field_of_study: '',
    start_date: '',
    end_date: '',
    url: '',
  })
  const [experince_Detailes, setExperince_Detailes] = useState([])
  const [education_Detailes, setEaducation_Detailes] = useState([])
  const [certificationc_Detailes, setCertification_Detailes] = useState([])
  const [servicestags, setServicestags] = useState([])
  const [conditiontags, setConditiontags] = useState([])
  const [experince, setexperince] = useState(0)
  const [pmdc, setPMDC] = useState('')
  const [specilization, setSpecilization] = useState('')
  const [coverletter, setCoverletter] = useState('')

  // remove data from array handle function
  const handle_Remove_experince_Detailes = (e) => {
    const name = e.target.getAttribute('data-id')
    const index = parseInt(name)
    setExperince_Detailes([
      ...experince_Detailes.slice(0, index),
      ...experince_Detailes.slice(index + 1),
    ])
    // console.log(experince_Detailes);
  }
  const handle_Remove_education_Detailes = (e) => {
    const name = e.target.getAttribute('data-id')
    console.log(name)
    const index = parseInt(name)
    setEaducation_Detailes([
      ...education_Detailes.slice(0, index),
      ...education_Detailes.slice(index + 1),
    ])
    // console.log(experince_Detailes);
  }
  const handle_Remove_certification_Detailes = (e) => {
    const name = e.target.getAttribute('data-id')
    // console.log(name)
    const index = parseInt(name)
    setCertification_Detailes([
      ...certificationc_Detailes.slice(0, index),
      ...certificationc_Detailes.slice(index + 1),
    ])
    // console.log(experince_Detailes);
  }
  // update data modal's
  const handle_update_exp_Detailes = (e) => {
    setShow_update_exp_modal(true)
    const index = e.target.getAttribute('data-id')
    // console.log(index)
    setIndex_update(index)
  }
  const handle_update_edu_Detailes = (e) => {
    setShow_update_edu_modal(true)
    const index = e.target.getAttribute('data-id')
    // console.log(index)
    setIndex_update(index)
  }
  const handle_update_cre_Detailes = (e) => {
    setShow_update_cer_modal(true)
    const index = e.target.getAttribute('data-id')
    // console.log(index)
    setIndex_update(index)
  }

  //upload experince document
  const handle_Upload_Experience_File = (e) => {
    setDocUploadSpin('spinner-border spinner-border-sm')
    setDoc_upload(false)
    e.preventDefault()
    setDoc_name(e.target.files[0].name)
    console.log(e.target.files[0].name)
    const [file] = e.target.files || e.dataTransfer.files

    uploadExperienceFile(file)
  }

  const uploadExperienceFile = (file) => {
    // preview img
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    //preview onload function.
    reader.onload = async () => {
      // this is the base64 data
      const fileRes = btoa(reader.result)
      const binaryImg = toArrayBuffer(fileRes)
      // console.log(binaryImg)
      await upload_experince_document_api(binaryImg)
      // console.log(imgURL);
    }
  }

  const upload_experince_document_api = async (imgBuffer) => {
    // let apiData;

    const data = imgBuffer
    await axios
      .post(`${API_CONSTANTS.BASEURL}admin/image-upload-api/user/${params.id}/type/new`, data, {
        headers: API_CONSTANTS.imgheaders(),
      })
      .then((r) => {
        console.log(r.data.response.url)
        // apiData = r.data.response.url;
        // console.log(apiData)
        setAddExperince({
          ...addExperince,
          url: r.data.response.url,
        })
        // console.log(uploadEduImg)
        setDoc_upload(true)
      })
      .catch((e) => {
        console.log(e + 'stuck')
        setDocUploadSpin('')
      })

    // return apiData;
  }

  // upload education document
  const handle_Upload_Education_File = (e) => {
    setEduDocUploadSpin('spinner-border spinner-border-sm')
    setEdu_Doc_upload(false)
    e.preventDefault()
    setEdu_Doc_name(e.target.files[0].name)
    console.log(e.target.files[0].name)
    const [file] = e.target.files || e.dataTransfer.files

    uploadEducationFile(file)
  }

  const uploadEducationFile = (file) => {
    // preview img
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    //preview onload function.
    reader.onload = async () => {
      // this is the base64 data
      const fileRes = btoa(reader.result)
      const binaryImg = toArrayBuffer(fileRes)
      // console.log(binaryImg)
      await upload_Education_document_api(binaryImg)
      // console.log(imgURL);
      // console.log(typeof imgURL);
      // setUploadEduImg(imgURL);
    }
  }

  const upload_Education_document_api = async (imgBuffer) => {
    // // console.log(imgBuffer);
    // let apiData;

    const data = imgBuffer
    await axios
      .post( `${API_CONSTANTS.BASEURL}admin/image-upload-api/user/${params.id}/type/new`, data, {
        headers: API_CONSTANTS.imgheaders(),
      })
      .then((r) => {
        // console.log(r.data.response.url)
        // apiData = r.data.response.url;
        setAddEducation({ ...addEducation, url: r.data.response.url })
        setEdu_Doc_upload(true)
      })
      .catch((e) => {
        console.log()
        setEduDocUploadSpin('')
      })

    // return apiData;
  }

  // upload education document
  const handle_Upload_Certification_File = (e) => {
    setCerdocUploadSpin('spinner-border spinner-border-sm')
    setCer_doc_upload(false)
    e.preventDefault()
    setCer_doc_name(e.target.files[0].name)
    console.log(e.target.files[0].name)
    const [file] = e.target.files || e.dataTransfer.files

    uploadCertificationFile(file)
  }

  const uploadCertificationFile = (file) => {
    // preview img
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    //preview onload function.
    reader.onload = async () => {
      // this is the base64 data
      const fileRes = btoa(reader.result)
      const binaryImg = toArrayBuffer(fileRes)
      // console.log(binaryImg)
      await upload_Certification_document_api(binaryImg)
    }
  }

  const upload_Certification_document_api = async (imgBuffer) => {
    const data = imgBuffer
    await axios
      .post( `${API_CONSTANTS.BASEURL}admin/image-upload-api/user/${params.id}/type/new`, data, {
        headers: API_CONSTANTS.imgheaders(),
      })
      .then((r) => {
        // console.log(r.data.response.url)
        // apiData = r.data.response.url;
        setAddCertification({ ...addCertification, url: r.data.response.url })
        setCer_doc_upload(true)
      })
      .catch((e) => {
        console.log(e + 'stuck')
        setEduDocUploadSpin('')
      })

    // return apiData;
  }

  // close modal and reset the form values
  const handleClose = () => {
    setShow(false)
    setDoc_upload(false)
    setDocUploadSpin('')
    // setUploadExpImg("");
    setAddExperince({
      ...addExperince,
      title: '',
      employment_type: '',
      company: '',
      location: '',
      start_date: '',
      end_date: '',
      currently_work: false,
      url: '',
    })

    setDoc_name('Upload the related documents in jpg, png or pdf.')
  }

  const handle_Close_Education_Modal = () => {
    setShow2(false)
    setEdu_Doc_upload(false)
    setEduDocUploadSpin('')
    setEdu_Doc_name('Upload the related documents in jpg, png or pdf.')
    setAddEducation({
      ...addEducation,
      institute_name: '',
      degree: '',
      field_of_study: '',
      location: '',
      start_date: '',
      end_date: '',
      url: '',
    })
  }

  const handle_Close_Certificate_Modal = () => {
    setShow3(false)
    setCer_doc_upload(false)
    setCerdocUploadSpin('')
    setCer_doc_name('Upload the related documents in jpg, png or pdf.')
    setAddCertification({
      ...addCertification,
      title: '',
      institute: '',
      field_of_study: '',
      start_date: '',
      end_date: '',
      url: '',
    })
  }

  // currently work here function
  const handle_currently_work_here = () => {
    setAddExperince({
      ...addExperince,
      end_date: '',
      currently_work: !addExperince.currently_work,
    })
  }

  // --------  This function is for call the Toast message  ------
  const handleToast = (message, icon, iconColor, className) => {
    setToastStates({
      ...ToastStates,
      icon: icon,
      message: message,
      color: iconColor,
      show: `show ${className}`,
    })
    setTimeout(() => setToastStates({ ...ToastStates, show: '' }), 2900)
  }

  useEffect(() => {
    axios
      .get( `${API_CONSTANTS.BASEURL}admin/doctors/${params.id}/updateDoctor/professional_info`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setExperince_Detailes(res.data.response.experiences || [])
        setEaducation_Detailes(res.data.response.educationalDocuments || [])
        setCertification_Detailes(res.data.response.certifications || [])
        setServicestags(res.data.response.services || [])
        setConditiontags(res.data.response.conditionsTreated || [])
        setPMDC(res.data.response.pmdc || '')
        setSpecilization(res.data.response.specialization || '')
        setCoverletter(res.data.response.coverLetter || '')
        setexperince(res.data.response.yearsOfExperience || null)
        console.log(res.data.response)
      })
      .catch((e) => console.log(e))
  }, [])

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          experiences: experince_Detailes,
          educationalDocuments: education_Detailes,
          certifications: certificationc_Detailes,
          pmdc: pmdc,
          specialization: specilization,
          coverLetter: coverletter,
          services: servicestags,
          conditionsTreated: conditiontags,
          yearsOfExperience: experince,
        }}
        validationSchema={Yup.object({
          experiences: Yup.array().min(
            1,
            'Enter atleast one record of experience. '
          ),
          educationalDocuments: Yup.array().min(
            1,
            'Enter atleast one record of education. '
          ),
          pmdc: Yup.string().required('Enter the RMP.'),
          specialization: Yup.string().required('Please select specialization'),
          services: Yup.array().min(1, 'Enter add Services. '),
          conditionsTreated: Yup.array().min(1, 'Enter add Conditions'),
          yearsOfExperience: Yup.number().required(
            'Please enter a years of experience'
          ),
          coverLetter: Yup.string().required('Please enter a cover letter.'),
        })}
        onSubmit={(values) => {
          setCreateSpin('spinner-border spinner-border-sm')
          console.log(values)
          axios
            .patch(`${API_CONSTANTS.BASEURL}admin/doctors/${params.id}/professionalInfo`, values, {
              headers: API_CONSTANTS.headers(),
            })
            .then((res) => {
              setCreateSpin('')
              // console.log(res)
              handleToast(
                // e.response.data.response.message,
                res.data.response.message,
                faCheckCircle,
                'rgba(0,155,148,0.93)',
                'show-success'
              )
              setTimeout(() => props.alert(stage), 2000)
            })
            .catch((e) => {
              setCreateSpin('')
              // console.log(e.response.data.response.message)
              handleToast(
                // e.response.data.response.message,
                e.response.data.response.message,
                faTimesCircle,
                '#b20909',
                'show-danger'
              )
            })
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div className='row py-3 ml-3 gutter-flx'>
                <div className='col-md-8 col-sm-12 border-bottom py-2'>
                  <div className={'d-flex justify-content-between w-100'}>
                    <span>Experience</span>
                    <span>
                      <FontAwesomeIcon
                        icon={faPlus}
                        onClick={handlebalShow}
                        className={'fa-lpus'}
                      />
                    </span>
                  </div>

                  {formik.values.experiences.map((data, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={
                            'd-flex justify-content-between w-100 py-1'
                          }
                        >
                          <div className={'col-md-10 p-0'}>
                            <h6 className={'m-0 title-heading '}>
                              {data.title}
                            </h6>
                            <p className={'m-0 sub-title-heading py-1'}>
                              {data.company} |{' '}
                              {moment(data.start_date).format('MMMM YYYY')} -{' '}
                              {data.currently_work
                                ? 'Currently Work here.'
                                : moment(data.end_date).format('MMMM YYYY')}
                            </p>
                            <p className={'m-0 sub-title-heading '}>
                              {data.location}
                            </p>
                          </div>
                          <div
                            className={
                              'col-md-2 p-0 justify-content-end d-flex'
                            }
                          >
                            <span>
                              <img
                                src={edit_svg_icon}
                                width={15}
                                height={16}
                                className={'mx-3'}
                                alt={'edit-icon'}
                                data-id={index}
                                onClick={handle_update_exp_Detailes}
                              />
                              <img
                                src={del_svg_icon}
                                width={16}
                                height={16}
                                data-id={index}
                                onClick={handle_Remove_experince_Detailes}
                                alt={'delete-icon'}
                              />
                            </span>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })}
                </div>
                {formik.touched.experiences && formik.errors.experiences ? (
                  <>
                    <br />
                    <div className={'error-field col-md-8 p-0'}>
                      {formik.errors.experiences}
                    </div>
                  </>
                ) : null}
              </div>
              <div className='row py-3 gutter-flx'>
                <div className='col-md-8 col-sm-12 border-bottom py-2'>
                  <div className={'justify-content-between d-flex'}>
                    <span>Education</span>
                    <span>
                      <FontAwesomeIcon
                        icon={faPlus}
                        className={'fa-lpus'}
                        onClick={() => setShow2(true)}
                      />
                    </span>
                  </div>
                  {formik.values.educationalDocuments.map((data, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={
                            'd-flex justify-content-between w-100 py-1'
                          }
                        >
                          <div className={'col-md-10 p-0'}>
                            <h6 className={'m-0 title-heading '}>
                              {data.institute_name}
                            </h6>
                            <p className={'m-0 sub-title-heading py-1'}>
                              {data.degree} |{' '}
                              {moment(data.start_date).format('MMMM YYYY')} -{' '}
                              {moment(data.end_date).format('MMMM YYYY')}
                            </p>
                            <p className={'m-0 sub-title-heading '}>
                              {data.location}
                            </p>
                          </div>
                          <div
                            className={
                              'col-md-2 p-0 justify-content-end d-flex'
                            }
                          >
                            <span>
                              <img
                                src={edit_svg_icon}
                                width={15}
                                height={16}
                                className={'mx-3'}
                                data-id={index}
                                onClick={handle_update_edu_Detailes}
                                alt={'edit-icon'}
                              />
                              <img
                                src={del_svg_icon}
                                width={16}
                                height={16}
                                data-id={index}
                                onClick={handle_Remove_education_Detailes}
                                alt={'delete-icon'}
                              />
                            </span>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })}
                </div>
                {formik.touched.educationalDocuments &&
                formik.errors.educationalDocuments ? (
                  <>
                    <br />
                    <div className={'error-field col-md-8 p-0'}>
                      {formik.errors.educationalDocuments}
                    </div>
                  </>
                ) : null}
              </div>
              <div className='row py-3 gutter-flx'>
                <div className='col-md-8 col-sm-12 border-bottom py-2'>
                  <div className={'justify-content-between d-flex w-100'}>
                    <span>Certifications</span>
                    <span>
                      <FontAwesomeIcon
                        icon={faPlus}
                        className={'fa-lpus'}
                        onClick={() => setShow3(true)}
                      />
                    </span>
                  </div>
                  {formik.values.certifications.map((data, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={
                            'd-flex justify-content-between w-100 py-1'
                          }
                        >
                          <div className={'col-md-10 p-0'}>
                            <h6 className={'m-0 title-heading '}>
                              {data.title}
                            </h6>
                            <p className={'m-0 sub-title-heading py-1'}>
                              {data.institute} |{' '}
                              {moment(data.start_date).format('MMMM YYYY')} -{' '}
                              {moment(data.end_date).format('MMMM YYYY')}
                            </p>
                            <p className={'m-0 sub-title-heading '}>
                              {data.field_of_study}
                            </p>
                          </div>
                          <div
                            className={
                              'col-md-2 p-0 justify-content-end d-flex'
                            }
                          >
                            <span>
                              <img
                                src={edit_svg_icon}
                                width={15}
                                height={16}
                                className={'mx-3'}
                                alt={'edit-icon'}
                                data-id={index}
                                onClick={handle_update_cre_Detailes}
                              />
                              <img
                                src={del_svg_icon}
                                width={16}
                                height={16}
                                data-id={index}
                                onClick={handle_Remove_certification_Detailes}
                                alt={'delete-icon'}
                              />
                            </span>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
              <div className='row py-3'>
                <div
                  className={'col-md-6'}
                  style={{
                    overflow: 'hidden',
                    height: '65px',
                    paddingTop: '6px',
                  }}
                >
                  <div className='group'>
                    <div>
                      <select
                        required
                        name={'specialization'}
                        value={formik.values.specialization}
                        style={{
                          margintop: '5px',
                          borderBottom: '1px solid #CCCCCC !important',

                          paddingTop: '13px',
                          borderTop: 'none',
                          borderLeft: 'none',
                          borderRight: 'none',
                        }}
                        onChange={(e) => {
                          // formik.handleChange(e);
                          setSpecilization(e.target.value)
                        }}
                      >
                        <option value='General Physician'>
                          General Physician
                        </option>
                        <option value='Dermatologist'>Dermatologist</option>
                        <option value='Child Specialist'>
                          Child Specialist
                        </option>
                        <option value='Skin Specialist'>
                          Skin Specialist
                        </option>
                        <option value='Diagnostic radiology'>
                          Diagnostic radiology
                        </option>
                        <option value='Anesthesiology'>Anesthesiology</option>
                        <option value='Pathology'>Pathology</option>
                      </select>

                      <label className={'p_label placeholder'} id='place'>
                        {' '}
                        Specialization
                      </label>
                    </div>
                  </div>
                  {formik.touched.specialization &&
                  formik.errors.specialization ? (
                    <div
                      className={'error-field col-md-8 p-0'}
                      style={{ marginTop: '40px' }}
                    >
                      {formik.errors.specialization}
                    </div>
                  ) : null}
                </div>
                <div className={'col-md-2 pl-0'} style={{ paddingTop: '7px' }}>
                  <div className='group mb-0'>
                    <input
                      type='text'
                      autoComplete={'false'}
                      placeholder='000000-P'
                      name={'pmdc'}
                      value={formik.values.pmdc}
                      maxLength={8}
                      onChange={(e) => {
                        // formik.handleChange(e);
                        e = e.target.value.split('-').join('') // Remove dash (-) if mistakenly entered.
                        let finalVal = (e.match(/.{1,6}/g) || []).join('-')

                        finalVal.length === 6 ? console.log() : console.log()
                        setPMDC(finalVal)
                      }}
                    />
                    <span className='highlight'></span>
                    <span className='bar'></span>
                    <label className={'p_label'}> RMP License Number</label>
                  </div>
                  {formik.touched.pmdc && formik.errors.pmdc ? (
                    <div className={'error-field col-md-8 p-0'}>
                      {formik.errors.pmdc}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='row border-0 py-3 gutter-flx'>
                <div className='col-md-8 justify-content-between d-flex p-0'>
                  <div className='group m-0'>
                    <ReactTagInput
                      tags={servicestags}
                      placeholder='Add services'
                      editable={true}
                      removeOnBackspace={true}
                      onChange={(newTags) => setServicestags(newTags)}
                    />
                    <span className='highlight'></span>
                    <span className='bar'></span>
                    <label className={'p_label'} style={{ top: '-21px' }}>
                      Services
                    </label>
                  </div>
                </div>
                {formik.touched.services && formik.errors.services ? (
                  <>
                    <br />
                    <div className={'error-field col-md-12 p-0'}>
                      {formik.errors.services}
                    </div>
                  </>
                ) : null}
              </div>
              <div className='row border-0 py-4 gutter-flx'>
                <div className='col-md-8 justify-content-between p-0'>
                  <div className='group mb-2'>
                    <ReactTagInput
                      tags={conditiontags}
                      placeholder='Add Conditions'
                      editable={true}
                      removeOnBackspace={true}
                      onChange={(newTags) => setConditiontags(newTags)}
                    />
                    <span className='highlight'></span>
                    <span className='bar'></span>
                    <label className={'p_label'} style={{ top: '-21px' }}>
                      Conditions Treated
                    </label>
                  </div>
                </div>
                {formik.touched.conditionsTreated &&
                formik.errors.conditionsTreated ? (
                  <>
                    <div className={'error-field col-md-12 p-0'}>
                      {formik.errors.conditionsTreated}
                    </div>
                  </>
                ) : null}
              </div>
              <div className='row pt-3  border-0 gutter-flx'>
                <div className='col-md-8 justify-content-between d-flex p-0 pt-2'>
                  <div className='group mb-0'>
                    <input
                      type='number'
                      maxLength={2}
                      name={'yearsOfExperience'}
                      value={formik.values.yearsOfExperience}
                      autoComplete={'false'}
                      onChange={(e) => {
                        let element = e.target
                        element.value = element.value.replace(/[^0-9]/gi, '')
                        // formik.handleChange(e);
                        setexperince(parseInt(e.target.value))
                      }}
                    />
                    <span className='highlight'></span>
                    <span className='bar'></span>
                    <label className={'p_label'}>Years of Experience</label>
                  </div>
                </div>
                {formik.touched.yearsOfExperience &&
                formik.errors.yearsOfExperience ? (
                  <>
                    <br />
                    <div className={'error-field col-md-8 pl-2'}>
                      {formik.errors.yearsOfExperience}
                    </div>
                  </>
                ) : null}
              </div>
              <div className='row py-3 border-0 gutter-flx'>
                <div className='col-md-8 border-0 p-0 pt-4'>
                  <div className={'d-flex'} style={{ position: 'relative' }}>
                    <span className={'cover-letter'}>
                      {formik.values.coverLetter.length}/{1000}
                    </span>
                    <textarea
                      className='form-control'
                      placeholder={'Cover letter'}
                      style={{ resize: 'none' }}
                      maxLength={1000}
                      value={coverletter}
                      rows='7'
                      onChange={(e) => {
                        setCoverletter(e.target.value)
                      }}
                    />
                  </div>
                  {formik.touched.coverLetter && formik.errors.coverLetter ? (
                    <>
                      <div className={'error-field col-md-8 p-0'}>
                        {formik.errors.coverLetter}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              <div className={'card-footer bg-white border-0 px-0'}>
                <div className={'  py-4'}>
                  <button className={'btn btn-save-Procced'} type={'submit'}>
                    <span className={Createspin} />
                    Save & Proceed
                  </button>
                  {/* <button
                    className={"btn btn btn-submit mx-3 "}
                    disabled={true}
                  >
                    Submit
                  </button>
                  <button disabled={true} className={"btn btn-save-exit"}>
                    Save & Exit
                  </button> */}
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>

      {/*  Add Experince Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Experience</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              title: addExperince.title,
              employment_type: addExperince.employment_type,
              company: addExperince.company,
              location: addExperince.location,
              start_date: addExperince.start_date,
              end_date: addExperince.end_date,
              currently_work: addExperince.currently_work,
              url: addExperince.url,
            }}
            validationSchema={Yup.object({
              title: Yup.string()
                .max(500, 'Must be 15 characters or less')
                .required('Please enter the valid job title'),
              employment_type: Yup.string().required(
                'Please select the valid job type'
              ),
              company: Yup.string().required('Please enter the company name'),
              location: Yup.string().required('Please enter the location'),
              start_date: Yup.string().required('Please enter the start date'),
              end_date: Yup.string().when('currently_work', {
                is: (currently_work) => currently_work === true,
                then: Yup.string(),
                otherwise: Yup.string().required('Please enter the end date'),
              }),
              url: Yup.string().required('Please upload image'),
            })}
            onSubmit={(values) => {
              console.log(values)
              let data = values
              for (var propName in data) {
                if (data[propName] === '' || data[propName] === false) {
                  delete data[propName]
                }
              }
              console.log(data)
              setExperince_Detailes((oldArray) => [...oldArray, data])
              handleClose()
            }}
          >
            {(formik) => {
              // console.log(overview.adminRole);
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'title'}
                          placeholder={'Ex: Senior Registrar'}
                          value={formik.values.title}
                          onChange={(e) => {
                            let element = e.target
                            element.value = element.value.replace(
                              /[^A-Za-z\s]/gi,
                              ''
                            )
                            setAddExperince({
                              ...addExperince,
                              title: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Title *</label>
                        {formik.touched.title && formik.errors.title ? (
                          <div className={'error-field'}>
                            {formik.errors.title}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={'col-md-12 '}
                      style={{
                        overflow: 'hidden',
                        height: '53px',
                        paddingTop: '6px',
                      }}
                    >
                      <div className='group'>
                        <div className='input-block'>
                          <select
                            className={'select'}
                            name={'employment_type'}
                            value={formik.values.employment_type}
                            style={{ margintop: '8px' }}
                            onChange={(e) => {
                              setAddExperince({
                                ...addExperince,
                                employment_type: e.target.value,
                              })
                            }}
                          >
                            <option value=''></option>
                            <option value='full_time'>Full Time</option>
                            <option value='part_time'>Part Time</option>
                          </select>

                          <label className={'p_label placeholder'} id='place'>
                            {' '}
                            Employment Type
                          </label>
                        </div>
                      </div>
                    </div>
                    {formik.touched.employment_type &&
                    formik.errors.employment_type ? (
                      <div className={'error-field ml-2'}>
                        {formik.errors.employment_type}
                      </div>
                    ) : null}
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Hameed Latif Hospital'}
                          name={'company'}
                          value={formik.values.company}
                          onChange={(e) => {
                            let element = e.target
                            element.value = element.value.replace(
                              /[^A-Za-z\s]/gi,
                              ''
                            )
                            setAddExperince({
                              ...addExperince,
                              company: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Company *</label>
                        {formik.touched.company && formik.errors.company ? (
                          <div className={'error-field'}>
                            {formik.errors.company}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Lahore, Pakistan'}
                          name={'location'}
                          value={formik.values.location}
                          onChange={(e) => {
                            setAddExperince({
                              ...addExperince,
                              location: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Location</label>
                        {formik.touched.location && formik.errors.location ? (
                          <div className={'error-field'}>
                            {formik.errors.location}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='start_date'
                          value={formik.values.start_date}
                          onChange={(e) => {
                            setAddExperince({
                              ...addExperince,
                              start_date: e.target.value,
                            })
                          }}
                          max={today_date}
                          autoComplete={'off'}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Start Date *</label>
                        <span className='bar'></span>
                        {formik.touched.start_date &&
                        formik.errors.start_date ? (
                          <div className={'error-field'}>
                            {formik.errors.start_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          disabled={addExperince.currently_work}
                          type='date'
                          name='end_date'
                          value={formik.values.end_date}
                          onChange={(e) => {
                            setAddExperince({
                              ...addExperince,
                              end_date: e.target.value,
                            })
                          }}
                          min={formik.values.start_date}
                          max={today_date}
                          style={{ height: '39px' }}
                          autoComplete={'off'}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>End Date *</label>
                        <span className='bar'></span>
                        {formik.touched.end_date && formik.errors.end_date ? (
                          <div className={'error-field'}>
                            {formik.errors.end_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12'}>
                      <div className={'group d-flex'}>
                        <input
                          type='checkbox'
                          onClick={handle_currently_work_here}
                          className={'checkbox'}
                        />
                        <label
                          className={'ml-5'}
                          style={{ marginLeft: '10px' }}
                        >
                          I currently work here
                        </label>
                      </div>
                    </div>
                    <div className={'col-md-12'}>
                      <div className={'group d-flex'}>
                        <input
                          id='default-btn'
                          ref={exp_InputRef}
                          name={'url'}
                          accept='image/*'
                          onChange={(e) => handle_Upload_Experience_File(e)}
                          type='file'
                          hidden
                        />
                        <button
                          id='custom-button'
                          onClick={() => exp_InputRef.current.click()}
                          className='px-3 ml-0 mb-0'
                          type='button'
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid #009b94',
                            color: '#009b94',
                            borderRadius: '6px',
                          }}
                        >
                          upload
                        </button>

                        <label
                          className={'m-0 ml-2 py-2'}
                          style={{ fontSize: '14px', fontWeight: '400' }}
                        >
                          {doc_name}
                          {formik.touched.url && formik.errors.url ? (
                            <div className={'error-field'}>
                              {formik.errors.url}
                            </div>
                          ) : null}
                        </label>
                        {doc_upload ? (
                          <label className={'m-0 ml-2 py-2'}>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </label>
                        ) : (
                          <span className={docUploadSpin + ' m-2'} />
                        )}
                      </div>
                    </div>

                    <div className={'col-md-12 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        {/*<span className={saveSpin} />*/}
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {/*  Add Education Modal */}
      <Modal show={show2} onHide={handle_Close_Education_Modal}>
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Education</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              institute_name: addEducation.institute_name,
              degree: addEducation.degree,
              field_of_study: addEducation.field_of_study,
              location: addEducation.location,
              start_date: addEducation.start_date,
              end_date: addEducation.end_date,
              url: addEducation.url,
            }}
            validationSchema={Yup.object({
              institute_name: Yup.string()
                .max(500, 'Must be 500 characters or less')
                .required('Please enter the valid job title'),
              degree: Yup.string().required('Please select the valid job type'),
              field_of_study: Yup.string().required(
                'Please select the valid job type'
              ),
              location: Yup.string().required('Please enter the location'),
              start_date: Yup.string().required('Please enter the start date'),
              end_date: Yup.string().required('Please enter the end date'),
              url: Yup.string().required('Please upload image'),
            })}
            onSubmit={(values) => {
              console.log(values)
              setEaducation_Detailes((oldArray) => [...oldArray, values])
              setShow2(false)
              // alert(JSON.stringify(values));
              // handle_update_Admin(values);
            }}
          >
            {(formik) => {
              // console.log(overview.adminRole);
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Agh Khan Medical University'}
                          name={'institute_name'}
                          value={formik.values.institute_name}
                          onChange={(e) => {
                            setAddEducation({
                              ...addEducation,
                              institute_name: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Institute *</label>
                        {formik.touched.institute_name &&
                        formik.errors.institute_name ? (
                          <div className={'error-field'}>
                            {formik.errors.institute_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Hameed Latif Hospital'}
                          name={'degree'}
                          value={formik.values.degree}
                          onChange={(e) => {
                            setAddEducation({
                              ...addEducation,
                              degree: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Degree *</label>
                        {formik.touched.degree && formik.errors.degree ? (
                          <div className={'error-field'}>
                            {formik.errors.degree}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Dentistry'}
                          name={'field_of_study'}
                          value={formik.values.field_of_study}
                          onChange={(e) => {
                            setAddEducation({
                              ...addEducation,
                              field_of_study: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Field of Study </label>
                        {formik.touched.field_of_study &&
                        formik.errors.field_of_study ? (
                          <div className={'error-field'}>
                            {formik.errors.field_of_study}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Lahore, Pakistan'}
                          name={'location'}
                          value={formik.values.location}
                          onChange={(e) => {
                            setAddEducation({
                              ...addEducation,
                              location: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Location</label>
                        {formik.touched.location && formik.errors.location ? (
                          <div className={'error-field'}>
                            {formik.errors.location}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='start_date'
                          value={formik.values.start_date}
                          onChange={(e) => {
                            setAddEducation({
                              ...addEducation,
                              start_date: e.target.value,
                            })
                          }}
                          max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Start Date *</label>
                        <span className='bar'></span>
                        {formik.touched.start_date &&
                        formik.errors.start_date ? (
                          <div className={'error-field'}>
                            {formik.errors.start_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='end_date'
                          value={formik.values.end_date}
                          onChange={(e) => {
                            setAddEducation({
                              ...addEducation,
                              end_date: e.target.value,
                            })
                          }}
                          min={formik.values.start_date}
                          // max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>
                          End Date (or expected) *
                        </label>
                        <span className='bar'></span>
                        {formik.touched.end_date && formik.errors.end_date ? (
                          <div className={'error-field'}>
                            {formik.errors.end_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12'}>
                      <div className={'group d-flex'}>
                        <input
                          id='default-btn'
                          ref={edu_InputRef}
                          name={'url'}
                          accept='image/*'
                          onChange={(e) => handle_Upload_Education_File(e)}
                          type='file'
                          hidden
                        />
                        <button
                          id='custom-button'
                          onClick={() => edu_InputRef.current.click()}
                          className='px-3 ml-0 mb-0'
                          type='button'
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid #009b94',
                            color: '#009b94',
                            borderRadius: '6px',
                          }}
                        >
                          upload
                        </button>

                        <label
                          className={'m-0 ml-2 py-2'}
                          style={{ fontSize: '14px', fontWeight: '400' }}
                        >
                          {edu_doc_name}
                          {formik.touched.url && formik.errors.url ? (
                            <div className={'error-field'}>
                              {formik.errors.url}
                            </div>
                          ) : null}
                        </label>
                        {edu_doc_upload ? (
                          <label className={'m-0 ml-2 py-2'}>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </label>
                        ) : (
                          <span className={edudocUploadSpin + ' m-2'} />
                        )}
                      </div>
                    </div>

                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {/*  Add certifications Modal */}
      <Modal show={show3} onHide={handle_Close_Certificate_Modal}>
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Certification</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              title: addCertification.title,
              institute: addCertification.institute,
              field_of_study: addCertification.field_of_study,
              start_date: addCertification.start_date,
              end_date: addCertification.end_date,
              url: addCertification.url,
            }}
            validationSchema={Yup.object({
              title: Yup.string()
                .max(500, 'Must be 500 characters or less')
                .required('Please enter the valid job title'),
              institute: Yup.string()
                .max(500, 'Must be 500 characters or less')
                .required('Please enter the Institute name'),
              field_of_study: Yup.string().required(
                'Please enter the field of study'
              ),
              start_date: Yup.string().required('Please enter the start date'),
              end_date: Yup.string().required('Please enter the end date'),
            })}
            onSubmit={(values) => {
              console.log(values)
              setCertification_Detailes((oldArray) => [...oldArray, values])
              setShow3(false)
            }}
          >
            {(formik) => {
              // console.log(overview.adminRole);
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'title'}
                          placeholder={'Ex: Registered Medical Assistant '}
                          value={formik.values.title}
                          onChange={(e) => {
                            setAddCertification({
                              ...addCertification,
                              title: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Title *</label>
                        {formik.touched.title && formik.errors.title ? (
                          <div className={'error-field'}>
                            {formik.errors.title}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'institute'}
                          placeholder={'Ex: Agh Khan Medical University'}
                          value={formik.values.institute}
                          onChange={(e) => {
                            setAddCertification({
                              ...addCertification,
                              institute: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Institute *</label>
                        {formik.touched.institute && formik.errors.institute ? (
                          <div className={'error-field'}>
                            {formik.errors.institute}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Dentistry'}
                          name={'field_of_study'}
                          value={formik.values.field_of_study}
                          onChange={(e) => {
                            setAddCertification({
                              ...addCertification,
                              field_of_study: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Field of Study</label>
                        {formik.touched.field_of_study &&
                        formik.errors.field_of_study ? (
                          <div className={'error-field'}>
                            {formik.errors.field_of_study}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='start_date'
                          value={formik.values.start_date}
                          onChange={(e) => {
                            setAddCertification({
                              ...addCertification,
                              start_date: e.target.value,
                            })
                          }}
                          max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Start Date *</label>
                        <span className='bar'></span>
                        {formik.touched.start_date &&
                        formik.errors.start_date ? (
                          <div className={'error-field'}>
                            {formik.errors.start_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          name='end_date'
                          type='date'
                          min={formik.values.start_date}
                          value={formik.values.end_date}
                          onChange={(e) => {
                            setAddCertification({
                              ...addCertification,
                              end_date: e.target.value,
                            })
                          }}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>
                          End Date (or expected) *
                        </label>
                        <span className='bar'></span>
                        {formik.touched.end_date && formik.errors.end_date ? (
                          <div className={'error-field'}>
                            {formik.errors.end_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12'}>
                      <div className={'group d-flex'}>
                        <input
                          id='default-btn'
                          ref={cer_InputRef}
                          name={'url'}
                          accept='image/*'
                          onChange={(e) => handle_Upload_Certification_File(e)}
                          type='file'
                          hidden
                        />
                        <button
                          id='custom-button'
                          onClick={() => cer_InputRef.current.click()}
                          className=' ml-0 mb-0'
                          type='button'
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid #009b94',
                            color: '#009b94',
                            borderRadius: '6px',
                          }}
                        >
                          upload
                        </button>

                        <label
                          className={'m-0 ml-5 py-2'}
                          style={{ fontSize: '14px', fontWeight: '400' }}
                        >
                          {cer_doc_name}
                          {formik.touched.url && formik.errors.url ? (
                            <div className={'error-field'}>
                              {formik.errors.url}
                            </div>
                          ) : null}
                        </label>
                        {cer_doc_upload ? (
                          <label className={'m-0 ml-2 py-2'}>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </label>
                        ) : (
                          <span className={cerdocUploadSpin + ' m-2'} />
                        )}
                      </div>
                    </div>

                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        {/*<span className={saveSpin} />*/}
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* ------------------------- Update Modal Code  ------------------------------- */}

      {/*  update Experience Modal */}
      <Modal show={Show_update_exp_modal} onHide={close_update_exp_modal}>
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Experience</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              title:
                experince_Detailes[index_update] &&
                experince_Detailes[index_update].title,
              employment_type:
                experince_Detailes[index_update] &&
                experince_Detailes[index_update].employment_type,
              company:
                experince_Detailes[index_update] &&
                experince_Detailes[index_update].company,
              location:
                experince_Detailes[index_update] &&
                experince_Detailes[index_update].location,
              start_date:
                experince_Detailes[index_update] &&
                experince_Detailes[index_update].start_date,
              end_date:
                experince_Detailes[index_update] &&
                experince_Detailes[index_update].end_date,
              currently_work:
                experince_Detailes[index_update] &&
                experince_Detailes[index_update].currently_work,
              url: 'https://eshaafi-doctor-image-upload-bucket-us-east-1-stage.s3.us-east-1.amazonaws.com/1642483795.387346.png',
            }}
            validationSchema={Yup.object({
              title: Yup.string()
                .max(500, 'Must be 500 characters or less')
                .required('Please enter the valid job title'),
              employment_type: Yup.string().required(
                'Please select the valid job type'
              ),
              company: Yup.string().required('Please enter the company name'),
              location: Yup.string().required('Please enter the location'),
              start_date: Yup.string().required('Please enter the start date'),
              end_date: Yup.string().required('Please enter the end date'),
            })}
            onSubmit={(values) => {
              let temp_state = [...experince_Detailes]
              let temp_element = { ...temp_state[index_update] }
              temp_element = values
              temp_state[index_update] = temp_element
              setExperince_Detailes(temp_state)
              setShow_update_exp_modal(false)
            }}
          >
            {(formik) => {
              // console.log(overview.adminRole);
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'title'}
                          placeholder={'Ex: Senior Registrar'}
                          value={formik.values.title}
                          onChange={(e) => {
                            let element = e.target
                            element.value = element.value.replace(
                              /[^A-Za-z\s]/gi,
                              ''
                            )
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Title *</label>
                        {formik.touched.title && formik.errors.title ? (
                          <div className={'error-field'}>
                            {formik.errors.title}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={'col-md-12 '}
                      style={{
                        overflow: 'hidden',
                        height: '53px',
                        paddingTop: '6px',
                      }}
                    >
                      <div className='group'>
                        <div className='input-block'>
                          <select
                            className={'select'}
                            name={'employment_type'}
                            value={formik.values.employment_type}
                            style={{ margintop: '8px' }}
                            onChange={(e) => {
                              formik.handleChange(e)
                            }}
                          >
                            <option value=''></option>
                            <option value='full_time'>Full Time</option>
                            <option value='part_time'>Part Time</option>
                          </select>
                          <span className='placeholder' id='place'>
                            Employment Type
                          </span>
                        </div>
                      </div>
                    </div>
                    {formik.touched.employment_type &&
                    formik.errors.employment_type ? (
                      <div className={'error-field ml-2'}>
                        {formik.errors.employment_type}
                      </div>
                    ) : null}
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Hameed Latif Hospital'}
                          name={'company'}
                          value={formik.values.company}
                          onChange={(e) => {
                            let element = e.target
                            element.value = element.value.replace(
                              /[^A-Za-z\s]/gi,
                              ''
                            )
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Company *</label>
                        {formik.touched.company && formik.errors.company ? (
                          <div className={'error-field'}>
                            {formik.errors.company}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Lahore, Pakistan'}
                          name={'location'}
                          value={formik.values.location}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Location</label>
                        {formik.touched.location && formik.errors.location ? (
                          <div className={'error-field'}>
                            {formik.errors.location}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='start_date'
                          value={formik.values.start_date}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                          max={today_date}
                          autoComplete={'off'}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Start Date *</label>
                        <span className='bar'></span>
                        {formik.touched.start_date &&
                        formik.errors.start_date ? (
                          <div className={'error-field'}>
                            {formik.errors.start_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          disabled={formik.values.currently_work}
                          type='date'
                          name='end_date'
                          value={formik.values.end_date}
                          onChange={(e) => formik.handleChange(e)}
                          min={formik.values.start_date}
                          max={today_date}
                          style={{ height: '39px' }}
                          autoComplete={'off'}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>End Date *</label>
                        <span className='bar'></span>
                        {formik.touched.end_date && formik.errors.end_date ? (
                          <div className={'error-field'}>
                            {formik.errors.end_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12'}>
                      <div className={'group d-flex'}>
                        <input
                          type='checkbox'
                          name='currently_work'
                          value={formik.values.currently_work}
                          checked={formik.values.currently_work}
                          onClick={(e) => {
                            formik.handleChange(e)
                          }}
                          className={'checkbox'}
                        />
                        <label>I currently work here</label>
                      </div>
                    </div>

                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        {/*<span className={saveSpin} />*/}
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {/*  update Education Modal */}
      <Modal show={Show_update_edu_modal} onHide={close_update_edu_modal}>
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Education</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              institute_name:
                education_Detailes[index_update] &&
                education_Detailes[index_update].institute_name,
              degree:
                education_Detailes[index_update] &&
                education_Detailes[index_update].degree,
              field_of_study:
                education_Detailes[index_update] &&
                education_Detailes[index_update].field_of_study,
              location:
                education_Detailes[index_update] &&
                education_Detailes[index_update].location,
              start_date:
                education_Detailes[index_update] &&
                education_Detailes[index_update].start_date,
              end_date:
                education_Detailes[index_update] &&
                education_Detailes[index_update].end_date,
              url: 'https://eshaafi-doctor-image-upload-bucket-us-east-1-stage.s3.us-east-1.amazonaws.com/1642483795.387346.png',
            }}
            validationSchema={Yup.object({
              institute_name: Yup.string()
                .max(500, 'Must be 500 characters or less')
                .required('Please enter the valid job title'),
              degree: Yup.string().required('Please select the valid job type'),
              field_of_study: Yup.string().required(
                'Please select the valid job type'
              ),
              location: Yup.string().required('Please enter the location'),
              start_date: Yup.string().required('Please enter the start date'),
              end_date: Yup.string().required('Please enter the end date'),
            })}
            onSubmit={(values) => {
              console.log(values)
              let temp_state = [...education_Detailes]
              let temp_element = { ...temp_state[index_update] }
              temp_element = values
              temp_state[index_update] = temp_element
              setEaducation_Detailes(temp_state)
              setShow_update_edu_modal(false)
            }}
          >
            {(formik) => {
              // console.log(overview.adminRole);
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Agh Khan Medical University'}
                          name={'institute_name'}
                          value={formik.values.institute_name}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Institute *</label>
                        {formik.touched.institute_name &&
                        formik.errors.institute_name ? (
                          <div className={'error-field'}>
                            {formik.errors.institute_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Hameed Latif Hospital'}
                          name={'degree'}
                          value={formik.values.degree}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Degree *</label>
                        {formik.touched.degree && formik.errors.degree ? (
                          <div className={'error-field'}>
                            {formik.errors.degree}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Dentistry'}
                          name={'field_of_study'}
                          value={formik.values.field_of_study}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Field of Study </label>
                        {formik.touched.field_of_study &&
                        formik.errors.field_of_study ? (
                          <div className={'error-field'}>
                            {formik.errors.field_of_study}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Lahore, Pakistan'}
                          name={'location'}
                          value={formik.values.location}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Location</label>
                        {formik.touched.location && formik.errors.location ? (
                          <div className={'error-field'}>
                            {formik.errors.location}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='start_date'
                          value={formik.values.start_date}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                          max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Start Date *</label>
                        <span className='bar'></span>
                        {formik.touched.start_date &&
                        formik.errors.start_date ? (
                          <div className={'error-field'}>
                            {formik.errors.start_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          disabled={formik.values.currently_work}
                          type='date'
                          name='end_date'
                          value={formik.values.end_date}
                          onChange={(e) => formik.handleChange(e)}
                          min={formik.values.start_date}
                          max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>End Date *</label>
                        <span className='bar'></span>
                        {formik.touched.end_date && formik.errors.end_date ? (
                          <div className={'error-field'}>
                            {formik.errors.end_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className={'col-md-12'}>
                      <div className={'group d-flex'}>
                        <button
                          className=''
                          type='button'
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid #009b94',
                            color: '#009b94',
                            borderRadius: '6px',
                          }}
                        >
                          upload
                        </button>
                        <label
                          className={' py-3'}
                          style={{
                            fontSize: '14px',
                            fontWeight: '400',
                            marginLeft: '10px',
                          }}
                        >
                          Upload the related documents in jpg, png or pdf.{' '}
                        </label>
                      </div>
                    </div> */}

                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {/*  update certifications Modal */}
      <Modal show={Show_update_cer_modal} onHide={close_update_cre_modal}>
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Certification</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              title:
                certificationc_Detailes[index_update] &&
                certificationc_Detailes[index_update].title,
              institute:
                certificationc_Detailes[index_update] &&
                certificationc_Detailes[index_update].institute,
              field_of_study:
                certificationc_Detailes[index_update] &&
                certificationc_Detailes[index_update].field_of_study,
              start_date:
                certificationc_Detailes[index_update] &&
                certificationc_Detailes[index_update].start_date,
              end_date:
                certificationc_Detailes[index_update] &&
                certificationc_Detailes[index_update].end_date,
              url:
                certificationc_Detailes[index_update] &&
                certificationc_Detailes[index_update].url,
            }}
            validationSchema={Yup.object({
              title: Yup.string()
                .max(500, 'Must be 500 characters or less')
                .required('Please enter the valid job title'),
              institute: Yup.string()
                .max(500, 'Must be 500 characters or less')
                .required('Please enter the Institute name'),
              field_of_study: Yup.string().required(
                'Please enter the field of study'
              ),
              start_date: Yup.string().required('Please enter the start date'),
              end_date: Yup.string().required('Please enter the end date'),
            })}
            onSubmit={(values) => {
              console.log(values)
              // setCertification_Detailes((oldArray) => [...oldArray, values]);
              let temp_state = [...certificationc_Detailes]
              let temp_element = { ...temp_state[index_update] }
              temp_element = values
              temp_state[index_update] = temp_element
              setCertification_Detailes(temp_state)
              // handle_update_Admin(values);
              setShow_update_cer_modal(false)
            }}
          >
            {(formik) => {
              // console.log(overview.adminRole);
              return (
                <Form>
                  <div className='row py-3 '>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'title'}
                          placeholder={'Ex: Registered Medical Assistant '}
                          value={formik.values.title}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Title *</label>
                        {formik.touched.title && formik.errors.title ? (
                          <div className={'error-field'}>
                            {formik.errors.title}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'institute'}
                          placeholder={'Ex: Agh Khan Medical University'}
                          value={formik.values.institute}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Institute *</label>
                        {formik.touched.institute && formik.errors.institute ? (
                          <div className={'error-field'}>
                            {formik.errors.institute}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Dentistry'}
                          name={'field_of_study'}
                          value={formik.values.field_of_study}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Field of Study</label>
                        {formik.touched.field_of_study &&
                        formik.errors.field_of_study ? (
                          <div className={'error-field'}>
                            {formik.errors.field_of_study}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='start_date'
                          value={formik.values.start_date}
                          onChange={(e) => formik.handleChange(e)}
                          max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Start Date *</label>
                        <span className='bar'></span>
                        {formik.touched.start_date &&
                        formik.errors.start_date ? (
                          <div className={'error-field'}>
                            {formik.errors.start_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          name='end_date'
                          type='date'
                          min={formik.values.start_date}
                          value={formik.values.end_date}
                          onChange={(e) => formik.handleChange(e)}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>
                          End Date (or expected) *
                        </label>
                        <span className='bar'></span>
                        {formik.touched.end_date && formik.errors.end_date ? (
                          <div className={'error-field'}>
                            {formik.errors.end_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className={'col-md-12'}>
                      <div className={'group d-flex'}>
                        <button
                          className='px-3'
                          type='button'
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid #009b94',
                            color: '#009b94',
                            borderRadius: '6px',
                          }}
                        >
                          upload
                        </button>
                        <label
                          className={'m-0 ml-2 py-3'}
                          style={{ fontSize: '14px', fontWeight: '400' }}
                        >
                          <a
                            href={formik.values.url}
                            target='_blank'
                            rel='noreferrer'
                          >
                            Click here to preview the img
                          </a>
                        </label>
                      </div>
                    </div> */}
                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        {/*<span className={saveSpin} />*/}
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* ------------------------- Toast Message Code  ------------------------------- */}
      <Toast
        cName={ToastStates.show}
        icon={ToastStates.icon}
        color={ToastStates.color}
        text={ToastStates.message}
      />
      <style>{`
     
      .gutter-flx{
        --bs-gutter-x: -1.rem;
      }
    /* form starting stylings ------------------------------- */
.add-doctor-form {
  width: 320px !important;
}

.btn-otp {
  margin: 0px !important;
  margin-left: 16px !important;
  padding: 8px 16px !important;
  font-size: 14px !important;
  height: 40px !important;
  border: 1px solid #009b94;
  border-radius: 4px;
  letter-spacing: 1.25px;
  color: #009b94;
  background-color: #ffffff;
}

.btn-otp:hover {
  background-color: rgba(0, 155, 148, 0.07) !important;
}

.btn-otp:active {
  outline: none;
}

.btn-otp:focus {
  outline: none;
}
error-feild{
   font-size: 14px;
          letter-spacing: 0.5px;
          color: #e52444;
}
button.search-field {
  padding: 0 15px;
  background-color: #009b94;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.searchInput {
  border: none;
  border-bottom: 1px solid white;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.searchInput:focus {
  border-color: #009b94 !important;
}
.search-field i {
  color: white;
}

.btn-createAccount:not(:disabled) {
  margin: 0px !important;
  background-color: #009b94 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1.25px;
  color: #ffffff;
  border: none;
  transition: 0.2s ease-in-out;
}

.btn-createAccount:hover {
  background-color: #99d7d4 !important;
}

.btn-createAccount:disabled {
  background-color: #99d7d4 !important;
  cursor: not-allowed !important;
  margin: 0px !important;
  color: #ffffff;
  border: none;
  transition: 0.2s ease-in-out;
}
.checkbox {
  width: fit-content !important;
  margin-top: 3px;
}

.input-danger,
.input-danger:focus {
  border-bottom: 1px solid #e52444 !important;
}

.error-field {
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #e52444;
}

/*  -------------------------------------   Add Doctor CSS   ----------------------------  */

/*.phone_input {*/
/*  width: fit-content;*/
/*  border: 1px none gray;*/
/*  border-bottom-style: solid;*/
/*}*/

.form-backLink {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #1e1e1e;
}

.fa-icon {
  color: #99d7d4 !important;
}

.perv,
.next {
  color: #99d7d4 !important;
}

.perv:hover,
.next:hover {
  color: #009b94 !important;
}

.btn-account {
  background: #009b94;
  border: 1px solid #ffffff;
  padding: 12px 16px;
  color: #ffffff;
  transition: 0.5s ease-in-out;
}

.btn-account:hover {
  background: #006b66;
  border: 1px solid #009b94;
  color: #ffffff;
  transition: 0.5s ease-in-out;
}

.btn-proceed {
  border: none !important;
  color: #009b94;
}

.btn-proceed:hover {
  border: none;
  box-shadow: none !important;
  text-decoration: underline;
  color: rgba(1, 190, 181, 0.87);
}

.d_list {
  color: #1e1e1e;
  font-size: 14px;
}

.d_list:hover {
  color: #1e1e1e;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}

.card-footer {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/*====================  Input field CSS Styling  ===========================*/

.group {
  position: relative;
  margin-bottom: 25px;
  width: 100% !important;
}
.group-done{
    position: relative;
  margin-bottom: 25px;
  width: 100% !important;
}
input,
.group select {
  font-size: 15px;
  padding: 12px 4px 2px 0px;
  display: block;
  border: none;
  border-bottom: 1px solid #cccccc;
}
input,
.group select-done {
  font-size: 15px;
  padding: 12px 4px 2px 0px;
  display: block;
  border: none;
  border-bottom: 1px solid #cccccc;
}


input {
  width: 100% !important;
}

.group select {
      width: 468px;
    /* height: 19px; */
    margin-top: 10px;
    /* padding: 0px; */
    -webkit-appearance: none;
    background-repeat: no-repeat;
    background-position-x: 38px;
    background-position-y: 21px;
    background-size: 844px 5px;
    cursor: pointer;
    // border: none;
    position: absolute;
    top: -9px;
    left: 0px;
}

.df-select {
  margin-top: 8px !important;
  background-position-x: 265px !important;
}


.cnic-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #5c5c5c;
  padding-top: 10px;
  margin-bottom: 0px;
  margin-left: 16px;
}

.front-cnic {
  width: 55px !important;
  height: 40px !important;
  border-radius: 2px;
}

.next-of-kin {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #5c5c5c;
  margin-top: 25px !important;
}

input:focus,
select {
  outline: none;
  border-bottom: 1px solid #009b94;
}

/* LABEL ======================================= */
label.p_label {
  color: #8e8e8e;
  font-size: 14px;
  font-weight: 400 !important;
  position: absolute;
  pointer-events: none;
  left: 1px;
  top: -10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus ~ label,
input:valid ~ label,
.group select:valid ~ label,
.group select:focus ~ label {
  top: -10px;
  font-size: 14px;
  color: #8e8e8e;
}

/* active state */
input:focus ~ .bar:before,
input:focus ~ .bar:after,
.group select:focus ~ .bar:before,
.group select:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;

  pointer-events: none;
  opacity: 0.5;
}

/* active state */
input:focus ~ .highlight,
.group select:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #f7f7f7;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: #f3f3f3;
  }

  to {
    width: 0;
    background: transparent;
  }
}

.input {
  position: absolute;
  font-size: 20px;
}

.div {
  position: relative;
  overflow: hidden;
}

/* .dropdown-form {
  width: 100%;
  margin: 0 20px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.3);
  font-family: "Lato", sans-serif;
}

@media (min-width: 768px) {
  .dropdown{
    width: 600px;
  }
} */

----

.dropdown__select {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 5px;
  overflow: hidden;
  box-shadow: 0 5px 10px 0 rgba(152, 152, 152, 0.6);
  transform: scaleY(0);
  transform-origin: top;
  font-weight: 300;
  transition: 0.2s ease-in-out;
}

.dropdown__select-option {
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #d6d6d6;
  transition: 0.3s;
}

.dropdown__select-option:last-of-type {
  border-bottom: 0;
}

.dropdown__select-option:hover {
  background-color: #f9f9f9;
}

.rapper {
  position: relative;
  height: 160px;
  width: 160px;
  border: 1px dashed #cccccc;
  border-radius: 6px;
  background-color: #f6f6f6;
  align-items: center;
  justify-content: center;
  display: flex;
}

.rapper .image {
  position: absolute;
  height: 160px;
  width: 160px;
  align-items: center;
  justify-content: center;
}

.rapper .image img {
  height: 160px;
  width: 160px;
  object-fit: cover;
}

.icon {
  color: #99d7d4;
  margin-right: 90px;
  font-size: 100px;
  margin-bottom: 20px;
  align-items: center;
  position: relative;
  left: 17%;
}

.rapper .text {
  font-size: 15px;
  font-weight: 400;
  /*margin-bottom: 100px;*/
  /*justify-content: center;*/
}

.img-heading {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #5c5c5c;
}

.profileImg {
  border-radius: 6px;
}

#custom-button {
  height: 40px;
  margin-bottom: 100px;
  // margin-left: 15px;
  color: #009b94;
  background-color: #ffffff;
  border: 1px solid;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

.delete-btn {
  width: 23px;
  height: 25px;
  margin-left: 12px;
  margin-top: 5px;
  border: none;
  outline: none;
  background-color: white;
  color: #ff6760;
}

.delete-btn:hover {
  color: rgba(255, 103, 96, 0.87);
}

#custom-button:focus,
.delete-btn:focus {
  outline: none;
}

.upload-btn {
  color: #009b94;
  background-color: #ffffff;
  border: 1px solid #009b94;
  border-radius: 6px;
  padding: 8px 20px;
}

.upload-btn:focus {
  outline: none;
}

.pra {
  margin-top: 8px;
  color: #5c5c5c;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 10px;
  padding-top: 10px;
}

.upload-bt {
  color: #009b94;
  background-color: #ffffff;
  border: 1px solid #009b94;
  border-radius: 6px;
  padding: 8px 20px;
}

.upload-bt:focus {
  outline: none;
}

.para {
  margin-top: 8px;
  color: #5c5c5c;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 10px;
  padding-top: 10px;
}

.customOverlay {
  background: rgba(36, 123, 160, 0.7);
}

.customModal {
  background: #b2dbbf;
  max-width: 500px;
  width: 100%;
}

.btn-save-Procced,
.btn-save-exit {
  margin: 0px !important;
  padding: 8px 16px !important;
  font-size: 14px !important;
  height: 40px !important;
  border: 1px solid #009b94;
  border-radius: 4px;
  letter-spacing: 1.25px;
  color: #009b94;
  background-color: #ffffff;
}

.btn-save-exit {
  color: #5c5c5c;
  border: 1px solid #5c5c5c;
}

.title-heading {
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #1e1e1e;
  font-weight: 400;
}
.sub-title-heading {
  font-size: 12px;
  color: #5c5c5c;
  font-weight: 400;
}

.react-tag-input__input {
  width: auto !important;
}
.react-tag-input__tag {
  width: auto !important;
  background: #eeeeee !important;
}

.react-tag-input {
  border: none !important;
  border-bottom: 1px solid #cccccc !important;
  border-radius: 0 !important;
  padding: 0.1875em 0 !important;
}

.react-tag-input__tag__remove {
  background: #eeeeee !important;
}

.react-tag-input__tag__remove:before,
.react-tag-input__tag__remove:after {
  background-color: #1ea7a1 !important;
}

textarea:focus {
  border-color: #009b94 !important;
}

.cover-letter {
  position: absolute;
  bottom: 4px;
  right: 10px;
  color: #8e8e8e;
}

.btn-white {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  color: #009b94;
  border: 1px solid #009b94;
}

select {
  background-color: white !important;
}



      
      `}</style>
    </>
  )
}

export default Professional_Info_Form
